document.addEventListener("turbolinks:load", function() {
  $('input[name="income.range"]').on('apply.daterangepicker', function() {
    $("#income-range").submit();
  });
  $('#all-delivery-jobs .today-delivery').on('click', function() {
    $('#all-delivery-jobs .all-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .month-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .week-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .today-delivery').addClass('rounded-pill bg-white text-info');
    $('.all-delivery-jobs').addClass('hide');
    $('.week-delivery-jobs').addClass('hide');
    $('.month-delivery-jobs').addClass('hide');
    $('.today-delivery-jobs').removeClass('hide');
  });
  $('#all-delivery-jobs .week-delivery').on('click', function() {
    $('#all-delivery-jobs .today-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .month-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .all-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .week-delivery').addClass('rounded-pill bg-white text-info');
    $('.today-delivery-jobs').addClass('hide');
    $('.month-delivery-jobs').addClass('hide');
    $('.all-delivery-jobs').addClass('hide');
    $('.week-delivery-jobs').removeClass('hide');
  });
  $('#all-delivery-jobs .month-delivery').on('click', function() {
    $('#all-delivery-jobs .today-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .week-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .all-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .month-delivery').addClass('rounded-pill bg-white text-info');
    $('.today-delivery-jobs').addClass('hide');
    $('.week-delivery-jobs').addClass('hide');
    $('.all-delivery-jobs').addClass('hide');
    $('.month-delivery-jobs').removeClass('hide');
  });
  $('#all-delivery-jobs .all-delivery').on('click', function() {
    $('#all-delivery-jobs .today-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .week-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .month-delivery').removeClass('rounded-pill bg-white text-info');
    $('#all-delivery-jobs .all-delivery').addClass('rounded-pill bg-white text-info');
    $('.today-delivery-jobs').addClass('hide');
    $('.week-delivery-jobs').addClass('hide');
    $('.month-delivery-jobs').addClass('hide');
    $('.all-delivery-jobs').removeClass('hide');
  });

  $('#all-return-jobs .today-return').on('click', function() {
    $('#all-return-jobs .all-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .month-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .week-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .today-return').addClass('rounded-pill bg-white text-info');
    $('.week-return-jobs').addClass('hide');
    $('.month-return-jobs').addClass('hide');
    $('.all-return-jobs').addClass('hide');
    $('.today-return-jobs').removeClass('hide');
  });
  $('#all-return-jobs .week-return').on('click', function() {
    $('#all-return-jobs .today-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .month-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .all-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .week-return').addClass('rounded-pill bg-white text-info');
    $('.today-return-jobs').addClass('hide');
    $('.month-return-jobs').addClass('hide');
    $('.all-return-jobs').addClass('hide');
    $('.week-return-jobs').removeClass('hide');
  });
  $('#all-return-jobs .month-return').on('click', function() {
    $('#all-return-jobs .today-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .week-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .month-return').addClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .all-return').removeClass('rounded-pill bg-white text-info');
    $('.today-return-jobs').addClass('hide');
    $('.week-return-jobs').addClass('hide');
    $('.month-return-jobs').removeClass('hide');
    $('.all-return-jobs').addClass('hide');
  });

  $('#all-return-jobs .all-return').on('click', function() {
    $('#all-return-jobs .today-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .week-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .month-return').removeClass('rounded-pill bg-white text-info');
    $('#all-return-jobs .all-return').addClass('rounded-pill bg-white text-info');
    $('.today-return-jobs').addClass('hide');
    $('.week-return-jobs').addClass('hide');
    $('.month-return-jobs').addClass('hide');
    $('.all-return-jobs').removeClass('hide');
  });
  
  $('#total-expense .today').on('click', function() {
    $('#total-expense .month').removeClass('rounded-pill bg-white text-info');
    $('#total-expense .week').removeClass('rounded-pill bg-white text-info');
    $(this).addClass('rounded-pill bg-white text-info');
    $('.week-expense').addClass('hide');
    $('.month-expense').addClass('hide');
    $('.today-expense').removeClass('hide');
  });
  $('#total-expense .week').on('click', function() {
    $('#total-expense .today').removeClass('rounded-pill bg-white text-info');
    $('#total-expense .month').removeClass('rounded-pill bg-white text-info');
    $(this).addClass('rounded-pill bg-white text-info');
    $('.today-expense').addClass('hide');
    $('.month-expense').addClass('hide');
    $('.week-expense').removeClass('hide');
  });
  $('#total-expense .month').on('click', function() {
    $('#total-expense .today').removeClass('rounded-pill bg-white text-info');
    $('#total-expense .week').removeClass('rounded-pill bg-white text-info');
    $(this).addClass('rounded-pill bg-white text-info');
    $('.today-expense').addClass('hide');
    $('.week-expense').addClass('hide');
    $('.month-expense').removeClass('hide');
  });
  $('#total-income .today').on('click', function() {
    $('#total-income .month').removeClass('rounded-pill bg-white text-info');
    $('#total-income .week').removeClass('rounded-pill bg-white text-info');
    $(this).addClass('rounded-pill bg-white text-info');
    $('.week-income').addClass('hide');
    $('.month-income').addClass('hide');
    $('.today-income').removeClass('hide');
  });
  $('#total-income .week').on('click', function() {
    $('#total-income .today').removeClass('rounded-pill bg-white text-info');
    $('#total-income .month').removeClass('rounded-pill bg-white text-info');
    $(this).addClass('rounded-pill bg-white text-info');
    $('.today-income').addClass('hide');
    $('.month-income').addClass('hide');
    $('.week-income').removeClass('hide');
  });
  $('#total-income .month').on('click', function() {
    $('#total-income .today').removeClass('rounded-pill bg-white text-info');
    $('#total-income .week').removeClass('rounded-pill bg-white text-info');
    $(this).addClass('rounded-pill bg-white text-info');
    $('.today-income').addClass('hide');
    $('.week-income').addClass('hide');
    $('.month-income').removeClass('hide');
  });
  $('#setting_percentage_w2_platform').on('input', function () {
    $('#setting_percentage_w2_runner').val((100.0 - $('#setting_percentage_w2_platform').val()).toFixed(1));
  });
  $('#setting_percentage_w2_runner').on('input', function () {
    $('#setting_percentage_w2_platform').val((100.0 - $('#setting_percentage_w2_runner').val()).toFixed(1));
  });
  $('#setting_percentage_1099_platform').on('input', function () {
    $('#setting_percentage_1099_runner').val((100.0 - $('#setting_percentage_1099_platform').val()).toFixed(1));
  });
  $('#setting_percentage_1099_runner').on('input', function () {
    $('#setting_percentage_1099_platform').val((100.0 - $('#setting_percentage_1099_runner').val()).toFixed(1));
  });
  $('#setting_percentage_w2_platform, #setting_percentage_w2_runner, #setting_percentage_1099_platform, #setting_percentage_1099_runner').keyup(function () {
    // Check correct, else revert back to old value.
    if (!$(this).val() || (parseInt($(this).val()) <= 100 && parseInt($(this).val()) >= 0))
      ;
    else
      $(this).val($(this).data("old"));
  });
  const internetStatus = document.getElementsByTagName("body")[0];
  window.addEventListener('load', function(event){
    detectInternet();
  });
  window.addEventListener('online', function(event){
      detectInternet();
  });
  window.addEventListener('offline', function(event){
      detectInternet();
  });
  function detectInternet(){
    if(!navigator.onLine){
      alert("No Internet Connection")
    }
  }
});
