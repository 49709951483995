$(document).on('turbolinks:load', function() {
  var removed_field_count = 0;
  var max_fields      = 25;
  var text_box_count = 1;
  var domain_name = $("#invitation_domain").val();
  var role = $("#invitation_hdn_role").val();
  var add_members_btn = document.getElementById("send_invites");
  var email_txt_field = /[@#$%^&*()\=\[\]{};':"\\|,<>\?]+/;

  if (role == "vendor") {
    var  role_label = "Technician"
    var  role_value = "service_tech"
    var  role_value_admin = "vendor"
  } else if (role == "owner_operator") {
    var  role_label = "Driver"
    var  role_value = "fleet_driver"
    var  role_value_admin = "owner_operator"
  } else {
    var  role_label = "Driver"
    var  role_value = "fleet_driver"
    var  role_value_admin = "carrier"
  }

  $(".add_field_button").click(function(e){ //on add input button click
    $('#send_invites').attr('disabled', 'disabled');
    add_members_btn.classList.remove('btn-primary');
    add_members_btn.classList.add('btn-light');
  	var inputs = '';
    inputs =
      `<div class="row mb-3">
  	    <div class="col-sm-5">
          <div class="form-group mb-0">
            <div class="input-group">
              <div id="email_field_${text_box_count}"  class="hide email_field_cls" >
                <input autofocus="autofocus" autocomplete="email" class="form-control form-control form-control-lg pl-0 validate" type="email" name="invitation[email][]", multiple: true, id="invitation_email_${text_box_count}">
              </div>
              <div id="domain_name_div_${text_box_count}" class="input-group-append bg-white ">
              <div id="text_field_${text_box_count}" class="text_field_cls">
                <input class="form-control form-control form-control-lg pl-0 validate" multiple="multiple" type="text" name="invitation[text][]",multiple: true, id="invitation_text_${text_box_count}">
              </div>
              <span class="input-group-text bg-primary-10 border-0">
                ${domain_name}  <i id="domain_name_${text_box_count}" class="far ml-2 fa-times domain_name_hide"></i>
              </span>
              </div>
              <div class="invalid-feedback"></div>
            </div>
          </div>
        </div>
        <div class="align-self-center col-auto px-0">
          <div class="form-check">
            <div class="invalid-feedback"></div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input validate_chk" type="radio"  value="${role_value_admin}" name="invitation[role][${text_box_count}]" class="check_role_admin" id="invitation_role_carrier_${text_box_count}">
              <label class="custom-control-label" for="invitation_role_carrier_${text_box_count}">Admin</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input validate_chk" type="radio" checked="checked" value="${role_value}" name="invitation[role][${text_box_count}]" id="invitation_role_fleet_driver_${text_box_count}">
              <label class="custom-control-label" for="invitation_role_fleet_driver_${text_box_count}">${role_label}</label>
            </div>
          </div>
        </div>
        <a href="#" class="remove_field">Delete</a>
      </div>`;
  	e.preventDefault();

  	if(text_box_count < max_fields) {
      text_box_count++;
      $(".dynamic_textbox").append(inputs);
  	}
  });

  $(".dynamic_textbox").on("click",".remove_field", function(e) {
    e.preventDefault();
    $(this).parent('div').remove();
    removed_field_count--;
    var input_gvn_count = 0;
    var input_field_loop_count = 0;
    $(".validate").each(function(){
      input_field_loop_count ++;
      input_gvn_count = validate(this, input_gvn_count)
      if (input_gvn_count == -1) {
        return false; 
      }
    });
    toggle_button(input_field_loop_count, input_gvn_count)
	})

  document.body.addEventListener('input', function(evt) {
    var input_gvn_count = 0;
    var input_field_loop_count = 0;
    var el = evt.srcElement;

    if ((el.classList.contains("validate")) || (el.classList.contains("validate_chk"))) {
      $(".validate").each(function() {
        input_field_loop_count ++;
        input_gvn_count = validate(this, input_gvn_count)
        if (input_gvn_count == -1) {
          return false; 
        }
      });
      toggle_button(input_field_loop_count, input_gvn_count)
    }
  });

  document.body.addEventListener("click", function (evt) {
    var el = evt.srcElement;
    if (el.classList.contains("domain_name_hide")) {
      $('#send_invites').attr('disabled', 'disabled');
      add_members_btn.classList.remove('btn-primary');
      add_members_btn.classList.add('btn-light');
      var div = el.parentElement.parentElement.id
      var dyn_id = div.split("_")[3]
      var domain_name_div = "#domain_name_div_" + dyn_id
      var email_field_div = "#email_field_" + dyn_id
      document.getElementById(div).style.display = "none";
      if (div == "domain_name_div")
      {
        $(domain_name_div).hide();
        $("#email_field").show();
      }
      else
      {
        $(domain_name_div).hide();
        $(email_field_div).show();
      }
    }
  });

  function validate(field_type, input_gvn_count) {
    if( $(field_type).is(":visible") && field_type.type == "text") {
      if( $(field_type).val().replace(/^\s+|\s+$/g, "").length == 0 || email_txt_field.test($(field_type).val()) ) {
        return -1;
      } else if (/(.*[a-z]){3}/i.test($(field_type).val())) {
        input_gvn_count++;
      }
    }
    else if( $(field_type).is(":visible") && field_type.type == "email") {
      if( $(field_type).val().replace(/^\s+|\s+$/g, "").length == 0 ) {
        return -1;
      } else if (/(.*[a-z]){3}/i.test($(field_type).val())) {
        input_gvn_count++;
      }
    }
    return input_gvn_count;
  }

  function toggle_button(input_field_loop_count, input_gvn_count) {
    if ( input_field_loop_count/2 == input_gvn_count ) {
      $("#send_invites").removeAttr('disabled');
      add_members_btn.classList.remove('btn-light');
      add_members_btn.classList.add('btn-primary');
      return true;
    } else {
      $('#send_invites').attr('disabled', 'disabled');
      add_members_btn.classList.remove('btn-primary');
      add_members_btn.classList.add('btn-light');
    }
  }
});
