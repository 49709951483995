require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("../javascript/Chart.bundle.min");

require("../javascript/table-filters");
require("../javascript/confirmation");
require("../javascript/chart");
require("../javascript/team_members");
require("../javascript/password_confirmation");
require("../javascript/two_factor_authentication");
require("../javascript/user_profile");
require("../javascript/show_password");
require("../javascript/unit");
require("../javascript/organization");
require("../javascript/jquery.mask");
require("../javascript/jquery.mask.min");
require("../javascript/dashboard");
require("../javascript/coupons");
