$(document).ready(function () {
    $("#txt_first_name").on('input', function(){ 
       toggle_password_button();
    });  

    $("#txt_last_name").on('input', function(){ 
        toggle_password_button();      
    }); 

    $("#txt_name").on('input', function(){ 
        toggle_password_button();
    }); 
    
    function toggle_password_button()
    {   
        user_profile = document.getElementById("save_user_profile");
        if ($("#txt_first_name").length > 0 && $("#txt_first_name").val().replace(/^\s+|\s+$/g, "").length > 0 && $("#txt_last_name").length > 0 && $("#txt_last_name").val().replace(/^\s+|\s+$/g, "").length > 0) {
            $("#save_user_profile").removeAttr('disabled');
            user_profile.classList.add('btn-primary');
            user_profile.classList.remove('btn-light'); 
        }
        else 
        {
            $("#save_user_profile").attr('disabled', 'true');
            user_profile.classList.remove('btn-primary');
            user_profile.classList.add('btn-light');
        }   
    }
});  
