var clickThroughToLink = function(event, chartArray){
  var chart = this;
  if(chartArray.length < 1){
    // do not fire if it was a label that was clicked
    return;
  }

  if(chart.canvas.dataset.chartLinks == undefined) {
    // chart links have not been provided, gracefully fail
    return;
  }

  var segmentIndex = chartArray[0]._index;
  var link = JSON.parse(chart.canvas.dataset.chartLinks)[segmentIndex];

  event.target.style.cursor = 'wait';
  window.Turbolinks.visit(link);
};

var chartsInit = function() {
  document.querySelectorAll('.js-pie-chart').forEach( el => {
    var colors = el.dataset.chartColors;

    new Chart(el, {
      type: 'pie',
      data: {
        labels: JSON.parse(el.dataset.chartLabels),
        datasets: [{
          data: JSON.parse(el.dataset.chartValues),
          backgroundColor: JSON.parse(colors),
          borderColor: '#f4f4f4',
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          position: 'right',
          labels: {
            boxWidth: 16,
            fontFamily: "'Nunito Sans', sans-serif",
            fontColor: '#3c3e41',
            fontSize: 16
          }
        },
        onClick: clickThroughToLink
      }
    });
  });

  document.querySelectorAll('.js-vertical-bar-chart').forEach(el => {
    var colors = JSON.parse(el.dataset.chartColors);
    var data = JSON.parse(el.dataset.chartValues);
    var stackLabels = JSON.parse(el.dataset.chartStackLabels);

    var datasets = data.map((dataset, i) => {
      return {
        label: stackLabels[i],
        backgroundColor: colors.pop(),
        borderColor: '#f4f4f4',
        data: dataset
      };
    });

    new Chart(el, {
      type: 'bar',
      data: {
        labels: JSON.parse(el.dataset.chartLabels),
        datasets: datasets
      },
      options: {
       scales: {
          xAxes: [{ ticks: { beginAtZero: true } }],
          yAxes: [{ ticks: { beginAtZero: true } }]
       },
       legend: {
          display: true
        },
        onClick: clickThroughToLink
      }
    });
  });

  document.querySelectorAll('.js-vertical-line-chart').forEach(el => {
    var colors = JSON.parse(el.dataset.chartColors);
    var data = JSON.parse(el.dataset.chartValues);
    var stackLabels = JSON.parse(el.dataset.chartStackLabels);

    var datasets = data.map((dataset, i) => {
      var color = colors.pop();

      return {
        label: stackLabels[i],
        backgroundColor: color,
        borderColor: color,
        data: dataset,
        fill: false
      };
    });

    new Chart(el, {
      type: 'line',
      data: {
        labels: JSON.parse(el.dataset.chartLabels),
        datasets: datasets
      },
      options: {
       scales: {
          xAxes: [{ ticks: { beginAtZero: true } }],
          yAxes: [{ ticks: { beginAtZero: true } }]
       },
       legend: {
          display: true
        },
        onClick: clickThroughToLink
      }
    });
  });

};

document.addEventListener('turbolinks:load', chartsInit);
