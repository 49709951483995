$(document).ready(function () {
  set_password = document.getElementsByClassName("set_password")[0];

  $(".txt_password").on('input', function(){
    six_character = document.getElementsByClassName("six_character")[0];
    one_capital_letter = document.getElementsByClassName("one_capital_letter")[0];
    one_number = document.getElementsByClassName("one_number")[0];
    password_field = $(".txt_password").val();

    const isNumber = (/\d/).test(password_field);
    const isCapitalLetter = (/[A-Z]/).test(password_field);

    if (isNumber) {
      toggle_psw_info(one_number, 'text-muted', 'text-success')
    } else {
      toggle_psw_info(one_number, 'text-success', 'text-muted')
    }

    if (isCapitalLetter) {
      toggle_psw_info(one_capital_letter, 'text-muted', 'text-success')
    } else {
      toggle_psw_info(one_capital_letter, 'text-success', 'text-muted')
    }

    if (password_field.length >= 6) {
      toggle_psw_info(six_character, 'text-muted', 'text-success')
    } else {
      toggle_psw_info(six_character, 'text-success', 'text-muted')
    }

    if (isNumber && isCapitalLetter && password_field.length >= 6) {
      toggle_psw_info(txt_password_circle, 'text-muted', 'text-success')
      document.getElementById("confpwd").disabled = false;
    } else {
      toggle_psw_info(txt_password_circle, 'text-success', 'text-muted')
      document.getElementById("confpwd").disabled = true;
    }

    if($(".txt_password").length > 0 && password_field.length > 0 && $(".psw-confirm").length > 0 && $(".psw-confirm").val().length > 0 ) {
      toggle_submit('btn-light', 'btn-primary')
    } else {
      toggle_submit('btn-primary', 'btn-light')
    }

    if ($(".txt_password").length > 0 && password_field.length > 0) {
      document.getElementById("password_instruction").style.display = 'inline';
      document.getElementsByClassName("password_confirm")[0].style.display = 'inline';
    } else {
      document.getElementById("password_instruction").style.display = 'none';
      document.getElementsByClassName("password_confirm")[0].style.display = 'none';
      toggle_psw_info(one_number, 'text-success', 'text-muted')
      toggle_psw_info(one_capital_letter, 'text-success', 'text-muted')
      toggle_psw_info(six_character, 'text-success', 'text-muted')
      toggle_psw_info(txt_password_circle, 'text-success', 'text-muted')
    }
  });

  function toggle_psw_info(class_name, remove_class, add_class) {
    class_name.classList.remove(remove_class);
    class_name.classList.add(add_class);
  }

  $(".psw-confirm").on('input', function(){
    if($(".txt_password").length > 0 && password_field.length > 0 && $(".psw-confirm").length > 0 && $(".psw-confirm").val().length > 0 ) {
      toggle_submit('btn-light', 'btn-primary')
    } else {
      toggle_submit('btn-primary', 'btn-light')
    }
  });

  function toggle_submit(remove_class, addClass) {
    if(remove_class == "btn-light") {
      $(".set_password").removeAttr('disabled');
    } else {
      $(".set_password").attr("disabled", true);
    }
    set_password.classList.remove(remove_class);
    set_password.classList.add(addClass);
  }
});
