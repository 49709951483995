$(document).on('turbolinks:load', function() {
  var field_count = 1;
  var add_units_btn = document.getElementById("add-units-submit");

  $("#add-units").on('click', function(e){
    $('#add-units-submit').attr("disabled", true)
    add_units_btn.classList.remove('btn-primary');
    add_units_btn.classList.add('btn-light');

    e.preventDefault();
    original = document.getElementById("form-wrapper")
    clone = original.cloneNode(true)
    inputs = clone.getElementsByTagName('input')
    labels = clone.getElementsByTagName('label')

    for (index = 0; index < inputs.length; ++index) {
      if(inputs[index].type == "text")
        inputs[index].value = '';
      if (inputs[index].type == "radio") {
        inputs[index].name = "unit[type]["+field_count+"]"
        inputs[index].id = inputs[index].id + field_count
        inputs[index].nextElementSibling.setAttribute("for", inputs[index].id)
      }
    }
    clone.id = "form-wrapper" + field_count
    original.parentNode.insertBefore(clone, original.parentNode.lastChild.nextSibling)
    field_count++;
  })

  document.body.addEventListener('input', function(e) {
    count = 0
    flag = 0
    $(".unit-input-field").each(function(){
      flag++
      if ($(this).val().length == 0) {
        count++
      }
    })

    if(count > 0) {
      $('#add-units-submit').attr("disabled", true)
      add_units_btn.classList.remove('btn-primary');
      add_units_btn.classList.add('btn-light');
    } else if(flag > 0) {
      $('#add-units-submit').removeAttr("disabled")
      add_units_btn.classList.remove('btn-light');
      add_units_btn.classList.add('btn-primary');
    }
  })

  $('.cls_select_all').change(function() {
    if ($(this).is(':checked')) {
      $(".cls_select").prop("checked", true);
      $("#delete_all").removeAttr('disabled');
      return true;
    }
    else
    {
      $(".cls_select").prop("checked", false);
      $('#delete_all').attr('disabled', 'disabled');
    }
  });

  $('.delete_all_cls').click(function(e) {
    var myArray = [];
    $(":checkbox.cls_select:checked").each(function() {
      myArray.push(this.value);
    });
    $('#unit_ids').val(myArray.join(","));
  });

  $('.cls_select').change(function() {
    count = 0
    if ($(this).is(':checked')) {
      count++
      return true;
    }
  });

  document.body.addEventListener('input', function(evt) {
    var el = evt.srcElement;
    if (el.classList.contains("cls_select")) {
      input_field_checked_count = 0
      input_field_count = 0
      $(".cls_select").each(function(){
        input_field_count++
        if ($(this).is(':checked')) {
          input_field_checked_count++
        }
      })
      if(input_field_checked_count == input_field_count) {
        $(".cls_select_all").prop("checked", true);
        $("#delete_all").removeAttr('disabled');
      } else if (input_field_checked_count > 1) {
        $(".cls_select_all").prop("checked", false);
        $("#delete_all").removeAttr('disabled');
      } else {
        $('#delete_all').attr('disabled', 'disabled');
        $(".cls_select_all").prop("checked", false);
      }
    }
  })
})
