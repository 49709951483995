$(document).ready(function () {
    update_master_checkbox();
    $("#coupon_start_date").on('change', function(){ 
       retrive_users();
    });  

    $("#coupon_end_date").on('change', function(){ 
        retrive_users();      
    }); 

    $("#coupon_job_count").on('change', function(){ 
        retrive_users();
    });

    $("#coupon_job_matching_criteria").on('change', function(){
        retrive_users();
    });

    $("#select_all").on('change', function() {
        $('.coupon_users').prop('checked', this.checked)
    });

    // $(".coupon_users").on("change", function () {
    $(document).on('change', '.coupon_users', function(){
        update_master_checkbox();
    });

    function update_master_checkbox() {
        if ($('.coupon_users').length == 0) {
            $("#select_all").prop("checked", false)
        } else if ($('.coupon_users:checked').length == $('.coupon_users').length) {
            $("#select_all").prop("checked", true)
        } else {
            $("#select_all").prop("checked", false)
        }
    }

    function retrive_users()
    {   
        $('#coupon_spinner').show();
        $.ajax({
            type: "POST",
            url: "/coupons/eligible_users",
            data: {
                user_filters: {
                  start_date: document.getElementById("coupon_start_date").value,
                  end_date: document.getElementById("coupon_end_date").value,
                  job_count: document.getElementById("coupon_job_count").value,
                  job_matching_criteria: document.getElementById("coupon_job_matching_criteria").value
                }
            },
            success: function(response) {
                $('#eligible_users_list').html("");
                response.data.map(function(item) {
                    $('#eligible_users_list').append(
                        '<label><input type="checkbox" name="coupon[user_ids][]" id="coupon_user_ids_" value="'+ item[0]+'" class="coupon_users">'+ item[1]+'</label>'
                    );
                });
                $('#coupon_spinner').hide();
                update_master_checkbox();
            },
            error: function(response) {
                console.log(response);
                $('#coupon_spinner').hide();
            }
        });
    }

    $('.btnNext').click(function() {
        $('.nav-tabs .active').parent().next('li').find('a').trigger('click');
    });

    $('.btnPrevious').click(function() {
        $('.nav-tabs .active').parent().prev('li').find('a').trigger('click');
    });
});
