var setupTableFilter = function() {
  var toggles = document.querySelectorAll(".js-table-filter-toggle");

  toggles.forEach(function(toggle){
    toggle.addEventListener('click', function(_event) {
      var thisDrawer = this.nextElementSibling;

      var drawers = document.querySelectorAll(".js-table-filter");
      drawers.forEach(function(drawer){
        if(drawer != thisDrawer){ drawer.classList.remove('active'); }
      });

      thisDrawer.classList.toggle('active');
    });
  });

  // close filter if clicking outside hover
  document.addEventListener('click', function(event) {
    var clickIsFilter = !!event.target.closest(".js-table-filter-container");

    // Don't close if click is the filter itself
    if(clickIsFilter) return;

    var drawers = document.querySelectorAll(".js-table-filter");
    drawers.forEach(function(drawer){
      if(drawer.previousElementSibling != event.target){
        drawer.classList.remove('active');
      }
    });
  });
};

document.addEventListener('turbolinks:load', setupTableFilter);
