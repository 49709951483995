$(document).on('turbolinks:load', function() {

  $('.phone_us').mask('(000) 000-0000');

  $("#two_factor_authentication_phone_number").on('input', function(){
    var ph_length = $("#two_factor_authentication_phone_number").val().length;
    otp = document.getElementById("otp-button");

    if(ph_length > 0) {
      $('#otp-button').removeAttr("disabled");
      otp.classList.add('btn-primary');
      otp.classList.remove('btn-light');
    }
    else {
      $('#otp-button').attr("disabled", true);
      otp.classList.add('btn-light');
      otp.classList.remove('btn-primary');
    }
  });

  $("#otp_form").on('submit', function(e) {
    combined_val = $("#digit-1").val() + $("#digit-2").val() + $("#digit-3").val() + $("#digit-4").val() + $("#digit-5").val() + $("#digit-6").val()
    $('#otp_form').append('<input type="hidden" name = "two_factor_authentication[otp]" value="'+combined_val+'" />');

    return true;
  })

  $(".otp-digits").on('input', function(){
    var combined_val = $("#digit-1").val() + $("#digit-2").val() + $("#digit-3").val() + $("#digit-4").val() + $("#digit-5").val() + $("#digit-6").val()
    confirm = document.getElementById("confirm-btn");

    if(combined_val.length == 6) {
      $('#confirm-btn').removeAttr("disabled");
      confirm.classList.add('btn-primary');
      confirm.classList.remove('btn-light');
    } else {
      $('#confirm-btn').attr("disabled", true);
      confirm.classList.add('btn-light');
      confirm.classList.remove('btn-primary');
    }
  });

  $("#tfa_form").on('submit', function(e) {
    area_code = document.getElementsByClassName("area-code")[0].innerText
    v = area_code + " " + $("#two_factor_authentication_phone_number").val()
    $('#tfa_form').append('<input type="hidden" name = "two_factor_authentication[phone_number]" value="'+v+'" />');

    return true;
  })

  $('.digit-group').find('input').each(function() {
  	$(this).attr('maxlength', 1);
  	$(this).on('keyup', function(e) {
  		var parent = $($(this).parent());

  		if(e.keyCode === 8 || e.keyCode === 37) {
  			var prev = parent.find('input#' + $(this).data('previous'));

  			if(prev.length) {
  				$(prev).select();
  			}
  		} else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
  			var next = parent.find('input#' + $(this).data('next'));

  			if(next.length) {
  				$(next).select();
  			} else {
  				if(parent.data('autosubmit')) {
  					parent.submit();
  				}
  			}
  		}
  	});
  });

  $('#resend-code-btn').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();
    var url = this.href.split('?')[0];
    var inputs = '';

    inputs += '<input type="hidden" name="two_factor_authentication[tfa_type]" value="text_message" />';
    inputs += '<input type="hidden" name="two_factor_authentication[user_id]" value="' + $("#user_id").val() + '" />';
    inputs += '<input type="hidden" name="two_factor_authentication[phone_number]" value="' + $("#phone_number").val() + '" />';

    $("body").append('<form action="'+url+'" method="post" id="resend">'+inputs+'</form>');
    $("#resend").submit();
  })
});
